type IntercomOptions = {
  email?: string
  created_at?: number
  name?: string
  user_id?: string
  hide_default_launcher?: boolean
  action_color?: string
  background_color?: string
  alignment?: 'left' | 'right'
}

export const APP_ID = 'fyn02nw7'

// prettier-ignore
export const load = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  // eslint-disable-next-line prefer-rest-params
  (function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;const i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

export const boot = (options?: IntercomOptions) => {
  if (window && window.Intercom) {
    window.Intercom('boot', { app_id: APP_ID, ...options })
  }
}

export const update = (options?: IntercomOptions) => {
  if (window && window.Intercom) {
    window.Intercom('update', options)
  }
}
