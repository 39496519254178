import { graphql } from '../../dto-types/src/gql/gql'

export const USER_FRAGMENT = graphql(`
  fragment UserFields on User {
    id
    firstName
    lastName
    email
    avatarUrl
    role
  }
`)

export const ORGANIZATION_USER_FRAGMENT = graphql(`
  fragment OrganizationUserFields on OrganizationUser {
    id
    user {
      ...UserFields
    }
    organizationId
  }
`)

export const SPACES_FRAGMENT = graphql(`
  fragment SpacesQuery on Space {
    __typename
    id
    name
    description
    spaceUrl
    icon
    iconColor
    owner {
      id
    }
    organization {
      id
      name
      teamUrl
    }
    projects {
      id
      name
      projectUrl
      icon
      iconColor
    }
    users {
      user {
        id
      }
    }
  }
`)

export const SPACE_FRAGMENT = graphql(`
  fragment SpaceFields on Space {
    __typename
    id
    name
    description
    spaceUrl
    icon
    iconColor
  }
`)

export const SPACE_SEARCH_FRAGMENT = graphql(`
  fragment SpaceSearch on Space {
    __typename
    id
    name
    description
    spaceUrl
    createdAt
    updatedAt
    deletedAt
    owner {
      id
    }
    organization {
      id
      name
      teamUrl
    }
    projects {
      id
      name
      projectUrl
      icon
      iconColor
    }
    users {
      user {
        id
      }
    }
  }
`)

export const ASSETS_SEARCH_FRAGMENT = graphql(`
  fragment AssetSearch on Asset {
    __typename
    id
    createdAt
    updatedAt
    deletedAt
    user {
      id
      email
      firstName
      lastName
      avatarUrl
    }
    name
    key
    version
    type
    documentType
    state
    originalMimeType
  }
`)

export const ORGANIZATION_SEARCH_FRAGMENT = graphql(`
  fragment OrganizationSearch on Organization {
    __typename
    id
    createdAt
    updatedAt
    deletedAt
    name
    teamUrl
    teamSize
    metadata
  }
`)

export const ORGANIZATION_FRAGMENT = graphql(`
  fragment OrganizationFields on Organization {
    __typename
    id
    name
    teamUrl
    metadata
    teamType
    type
    logoUrl
  }
`)

export const PROJECT_SEARCH_FRAGMENT = graphql(`
  fragment ProjectSearch on Project {
    __typename
    id
    createdAt
    updatedAt
    deletedAt
    name
    projectUrl
    description
    isPublic
    createdBy {
      id
      firstName
      lastName
      email
    }
    space {
      name
    }
  }
`)

export const PAGINATION_SEARCH_FRAGMENT = graphql(`
  fragment PaginationSearch on PaginationMeta {
    totalItems
    itemsPerPage
    totalPages
    currentPage
  }
`)

export const AUDIT_TRAIL_FRAGMENT = graphql(`
  fragment AuditTrail on AuditTrail {
    __typename

    id
    organizationId

    createdAt
    contextId

    action

    entityId
    entityType

    sourceId
    sourceType

    metadata
  }
`)

export const PROCESSES_FRAGMENT = graphql(`
  fragment ProcessFields on Process {
    __typename
    id
    status
    access
    title
    description
    space {
      id
      name
      icon
      iconColor
    }
    members {
      ...ProcessMemberFields
    }
    fields {
      ...ProcessFieldFields
    }
    createdAt
    updatedAt
    deletedAt
  }
`)

export const PROCESS_MEMBERS_FRAGMENT = graphql(`
  fragment ProcessMemberFields on ProcessMember {
    __typename
    id
    user {
      id
      email
      firstName
      lastName
      avatarUrl
    }
  }
`)

export const PROCESS_FIELDS_FRAGMENT = graphql(`
  fragment ProcessFieldFields on ProcessField {
    __typename
    id
    title
    description
    type
    isRequired
    options
  }
`)

export const ACTIVITY_TASK_FRAGMENT = graphql(`
  fragment ActivityTaskFields on ActivityTask {
    __typename
    id
    createdAt
    updatedAt
    deletedAt
    dueDate
    description
    submissionStatus
    summary
  }
`)

export const ACTIVITY_TASK_DETAILS_FRAGMENT = graphql(`
  fragment ActivityTaskDetails on ActivityTask {
    __typename
    createdAt
    deletedAt
    description
    dueDate
    id
    submissionStatus
    updatedAt
    summary
    assignedTo {
      id
      user {
        ...UserFields
      }
    }
    latestSubmission {
      time
      actingUser {
        id
        user {
          ...UserFields
        }
      }
      explanation
    }
    firstApproval {
      time
      explanation
      actingUser {
        id
        user {
          ...UserFields
        }
      }
    }
    subscription {
      id
      optedOut
      activity {
        id
        name
        description
        responsibleParty
        topic {
          id
          name
        }
        subtopic {
          id
          name
        }
      }
      organization {
        id
        name
      }
      sourceBank {
        id
        name
      }
      owner {
        id
        user {
          ...UserFields
        }
      }
      approver {
        id
        user {
          ...UserFields
        }
      }
    }
    comments {
      id
      content
      createdAt
      author {
        id
        ...UserFields
      }
    }
    assets {
      id
      name
      key
      version
      originalMimeType
      createdAt
      user {
        ...UserFields
      }
    }
    attachments {
      id
      name
      fileKey
    }
    events {
      id
      createdAt
      type
      explanation
      actingUser {
        id
        user {
          ...UserFields
        }
      }
    }
  }
`)

export const ACTIVITY_FRAGMENT = graphql(`
  fragment ActivityFields on Activity {
    __typename
    id
    name
    description
    updateFrequency
    responsibleParty
    active
    createdAt
    updatedAt
  }
`)

export const TOPIC_FRAGMENT = graphql(`
  fragment TopicFields on Topic {
    __typename
    id
    name
    description
    createdAt
  }
`)

export const SUBTOPIC_FRAGMENT = graphql(`
  fragment SubtopicFields on Subtopic {
    __typename
    id
    name
    description
    createdAt
  }
`)

export const ACTIVITY_SUBSCRIPTION_FRAGMENT = graphql(`
  fragment ActivitySubscriptionFields on ActivitySubscription {
    __typename
    id
    description
    inheritedFromBank
    optedOut
    status
    dueDate
  }
`)

export const PROJECT_FRAGMENT = graphql(`
  fragment ProjectFields on Project {
    id
    name
    projectUrl
    description
    isPublic
    createdAt
    icon
    iconColor
    publicFormHash
  }
`)

export const COMMENT_FRAGMENT = graphql(`
  fragment CommentFields on Comment {
    id
    content
    createdAt
    updatedAt
    author {
      id
      firstName
      lastName
      avatarUrl
      email
    }
  }
`)

export const SUGGESTION_METADATA_FRAGMENT = graphql(`
  fragment SuggestionMetadataFields on SuggestionMetadata {
    __typename
    coordinates {
      points
      system
      layout_width
      layout_height
    }
    filetype
    languages
    page_number
    filename
    parent_id
  }
`)

export const SUGGESTION_FRAGMENT = graphql(`
  fragment SuggestionFields on Suggestion {
    id
    highlightedText
    reasonForChange
    highLevelReason
    potentialAlternateCopy
    resolveType
    sources
    severity
    isStillRelevantFromPreviousVersion
    createdAt
    updatedAt
    deletedAt
    metadata {
      ...SuggestionMetadataFields
    }
  }
`)

export const ASSET_ISSUE_FRAGMENT = graphql(`
  fragment AssetIssueFields on AssetIssue {
    id
    title
    description
    status
    createdAt
    updatedAt
    comments {
      ...CommentFields
    }
    suggestion {
      ...SuggestionFields
    }
  }
`)

export const ASSET_FRAGMENT = graphql(`
  fragment AssetFields on Asset {
    __typename
    id
    name
    key
    version
    originalMimeType
    createdAt
    updatedAt
    deletedAt
    type
    documentType
    versionIds
    version
    state
    originalFileKey
    originalMimeType
    vectorDbState
    suggestionsJobRunning
    partnerProgramType
    partnerProductType
  }
`)

export const ASSET_REVIEW_FRAGMENT = graphql(`
  fragment AssetReviewFields on AssetReview {
    __typename
    id
    summary
    metadata
    assetRevisionId
    createdAt
    updatedAt
    deletedAt
  }
`)

export const PROJECT_TASK_FRAGMENT = graphql(`
  fragment ProjectTaskFields on ProjectTask {
    id
    name
    description
    dueDate
    priority
    status
    archivedAt
    createdAt
    updatedAt
    deletedAt
  }
`)
