import {
  AcceptOrganizationInvitationMutation,
  AcceptOrganizationInvitationMutationVariables,
  AcceptProjectInvitationMutation,
  AcceptProjectInvitationMutationVariables,
  AcceptSpaceInvitationMutation,
  AcceptSpaceInvitationMutationVariables,
  AddActivityTaskCommentMutation,
  AddActivityTaskCommentMutationVariables,
  AddAssetToActivityTaskMutation,
  AddAssetToActivityTaskMutationVariables,
  AddCommentToAssetIssueMutation,
  AddCommentToAssetIssueMutationVariables,
  AdvanceWorkflowMutation,
  AdvanceWorkflowMutationVariables,
  ApproveActivityTaskMutation,
  ApproveActivityTaskMutationVariables,
  ArchiveProjectMutation,
  ArchiveProjectMutationVariables,
  ArchiveProjectTaskMutation,
  ArchiveProjectTaskMutationVariables,
  ArchiveSpaceMutation,
  ArchiveSpaceMutationVariables,
  ArchiveWorkflowTemplateMutation,
  ArchiveWorkflowTemplateMutationVariables,
  BumpAssetVersionMutation,
  BumpAssetVersionMutationVariables,
  CancelInvitationMutation,
  CancelInvitationMutationVariables,
  CancelProjectInvitationMutation,
  CancelProjectInvitationMutationVariables,
  CancelSpaceInvitationMutation,
  CancelSpaceInvitationMutationVariables,
  CloseAssetIssueMutation,
  CloseAssetIssueMutationVariables,
  ConvertS3FileToHtmlMutation,
  ConvertS3FileToHtmlMutationVariables,
  CreateActivityMutation,
  CreateActivityMutationVariables,
  CreateActivitySubscriptionMutation,
  CreateActivitySubscriptionMutationVariables,
  CreateActivityTaskAttachmentsMutation,
  CreateActivityTaskAttachmentsMutationVariables,
  CreateActivityTaskAttachmentUploadUrlMutation,
  CreateActivityTaskAttachmentUploadUrlMutationVariables,
  CreateActivityTaskFromSubscriptionMutation,
  CreateActivityTaskFromSubscriptionMutationVariables,
  CreateApiCredentialMutation,
  CreateApiCredentialMutationVariables,
  CreateAssetIssueForSuggestionMutation,
  CreateAssetIssueForSuggestionMutationVariables,
  CreateAssetIssueMutation,
  CreateAssetIssueMutationVariables,
  CreateAssetReviewRequestMutation,
  CreateAssetReviewRequestMutationVariables,
  CreateBankSubscriptionMutation,
  CreateBankSubscriptionMutationVariables,
  CreateCommentMutation,
  CreateCommentMutationVariables,
  CreateCommentThreadMutation,
  CreateCommentThreadMutationVariables,
  CreateComplaintsFromPublicFormMutation,
  CreateComplaintsFromPublicFormMutationVariables,
  CreateInternalComplaintMutation,
  CreateInternalComplaintMutationVariables,
  CreateIssuesForPartnershipMutation,
  CreateIssuesForPartnershipMutationVariables,
  CreateIssuesForPartnerSourceMutation,
  CreateIssuesForPartnerSourceMutationVariables,
  CreateMosaicPartnershipMutation,
  CreateMosaicPartnershipMutationVariables,
  CreateNotificationPreferencesUserMutation,
  CreateNotificationPreferencesUserMutationVariables,
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
  CreateOrUpdateDocumentAssetRevisionsMutation,
  CreateOrUpdateDocumentAssetRevisionsMutationVariables,
  CreatePartnershipMutation,
  CreatePartnershipMutationVariables,
  CreatePartnerSourceMutation,
  CreatePartnerSourceMutationVariables,
  CreatePartnerSourcesMutation,
  CreatePartnerSourcesMutationVariables,
  CreateProcessMembersMutation,
  CreateProcessMembersMutationVariables,
  CreateProcessMutation,
  CreateProcessMutationVariables,
  CreateProgramMutation,
  CreateProgramMutationVariables,
  CreateProjectMutation,
  CreateProjectMutationVariables,
  CreateProjectTaskAttachmentsMutation,
  CreateProjectTaskAttachmentsMutationVariables,
  CreateProjectTaskAttachmentUploadUrlMutation,
  CreateProjectTaskAttachmentUploadUrlMutationVariables,
  CreateProjectTaskFromPublicFormMutation,
  CreateProjectTaskFromPublicFormMutationVariables,
  CreateProjectTaskMutation,
  CreateProjectTaskMutationVariables,
  CreateProjectWorkflowTemplateMutation,
  CreateProjectWorkflowTemplateMutationVariables,
  CreateRuleMutation,
  CreateRuleMutationVariables,
  CreateSpaceMutation,
  CreateSpaceMutationVariables,
  CreateWorkflowTemplateMutation,
  CreateWorkflowTemplateMutationVariables,
  DeleteActivitiesMutation,
  DeleteActivitiesMutationVariables,
  DeleteActivityMutation,
  DeleteActivityMutationVariables,
  DeleteActivitySubscriptionCascadeMutation,
  DeleteActivitySubscriptionCascadeMutationVariables,
  DeleteActivitySubscriptionMutation,
  DeleteActivitySubscriptionMutationVariables,
  DeleteApiCredentialMutation,
  DeleteApiCredentialMutationVariables,
  DeleteAssetFromActivityTaskMutation,
  DeleteAssetFromActivityTaskMutationVariables,
  DeleteAssetMutation,
  DeleteAssetMutationVariables,
  DeleteCommentMutation,
  DeleteCommentMutationVariables,
  DeleteCommentThreadMutation,
  DeleteCommentThreadMutationVariables,
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables,
  DeleteIssueMutation,
  DeleteIssueMutationVariables,
  DeleteMosaicPartnershipMutation,
  DeleteMosaicPartnershipMutationVariables,
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables,
  DeletePartnershipMutation,
  DeletePartnershipMutationVariables,
  DeletePartnerSourceMutation,
  DeletePartnerSourceMutationVariables,
  DeleteProcessFieldsMutation,
  DeleteProcessFieldsMutationVariables,
  DeleteProgramMutation,
  DeleteProgramMutationVariables,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  DeleteSlackDataChannelMutation,
  DeleteSlackDataChannelMutationVariables,
  DeleteSpaceMutation,
  DeleteSpaceMutationVariables,
  DeleteWorkflowTemplateMutation,
  DeleteWorkflowTemplateMutationVariables,
  GetOrCreateLiveblocksRoomMutation,
  GetOrCreateLiveblocksRoomMutationVariables,
  IgnoreSuggestionsForAssetMutation,
  IgnoreSuggestionsForAssetMutationVariables,
  InitiatePartnerOnboardingMutation,
  InitiatePartnerOnboardingMutationVariables,
  InviteOrganizationMemberMutation,
  InviteOrganizationMemberMutationVariables,
  InviteOrganizationMembersMutation,
  InviteOrganizationMembersMutationVariables,
  InviteProjectMembersMutation,
  InviteProjectMembersMutationVariables,
  InviteSpaceMembersMutation,
  InviteSpaceMembersMutationVariables,
  JoinSpaceMutation,
  JoinSpaceMutationVariables,
  LeaveOrganizationMutation,
  LeaveOrganizationMutationVariables,
  LeaveProjectMutation,
  LeaveProjectMutationVariables,
  LeaveSpaceMutation,
  LeaveSpaceMutationVariables,
  MarkOutOfScopeMutation,
  MarkOutOfScopeMutationVariables,
  OptOutOfSubscriptionsMutation,
  OptOutOfSubscriptionsMutationVariables,
  PublishProjectFormMutation,
  PublishProjectFormMutationVariables,
  QueuePartnerOnboardingMutation,
  QueuePartnerOnboardingMutationVariables,
  QueueSuggestionsJobForAssetMutation,
  QueueSuggestionsJobForAssetMutationVariables,
  ReassignActivitySubscriptionsApproversMutation,
  ReassignActivitySubscriptionsApproversMutationVariables,
  ReassignActivitySubscriptionsMutation,
  ReassignActivitySubscriptionsMutationVariables,
  ReassignActivitySubscriptionsOwnersMutation,
  ReassignActivitySubscriptionsOwnersMutationVariables,
  ReassignActivityTasksMutation,
  ReassignActivityTasksMutationVariables,
  RegenerateApiCredentialMutation,
  RegenerateApiCredentialMutationVariables,
  RequestChangesToActivityTaskMutation,
  RequestChangesToActivityTaskMutationVariables,
  ResolveAssetIssueMutation,
  ResolveAssetIssueMutationVariables,
  RunSinglePartnerSourceMutation,
  RunSinglePartnerSourceMutationVariables,
  SaveProcessFieldsMutation,
  SaveProcessFieldsMutationVariables,
  SendLogAnalysisInsightsChatMessageMutation,
  SendLogAnalysisInsightsChatMessageMutationVariables,
  SetProcessMembersMutation,
  SetProcessMembersMutationVariables,
  SubmitActivityTaskMutation,
  SubmitActivityTaskMutationVariables,
  TypedDocumentNode,
  UnpublishProjectFormMutation,
  UnpublishProjectFormMutationVariables,
  UpdateActivityMutation,
  UpdateActivityMutationVariables,
  UpdateActivityTaskAssigneeMutation,
  UpdateActivityTaskAssigneeMutationVariables,
  UpdateActivityTaskDueDateMutation,
  UpdateActivityTaskDueDateMutationVariables,
  UpdateActivityTaskStatusMutation,
  UpdateActivityTaskStatusMutationVariables,
  UpdateAssetReviewRequestMutation,
  UpdateAssetReviewRequestMutationVariables,
  UpdateCommentMutation,
  UpdateCommentMutationVariables,
  UpdateCommentThreadMutation,
  UpdateCommentThreadMutationVariables,
  UpdateDefaultOrganizationUserMutation,
  UpdateDefaultOrganizationUserMutationVariables,
  UpdateNotificationPreferencesProjectMutation,
  UpdateNotificationPreferencesProjectMutationVariables,
  UpdateNotificationPreferencesSpaceMutation,
  UpdateNotificationPreferencesSpaceMutationVariables,
  UpdateNotificationPreferencesUserMutation,
  UpdateNotificationPreferencesUserMutationVariables,
  UpdateOrganizationMetadataMutation,
  UpdateOrganizationMetadataMutationVariables,
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables,
  UpdateOrganizationUserMutation,
  UpdateOrganizationUserMutationVariables,
  UpdatePartnershipMutation,
  UpdatePartnershipMutationVariables,
  UpdatePartnerSourceMutation,
  UpdatePartnerSourceMutationVariables,
  UpdateProcessMutation,
  UpdateProcessMutationVariables,
  UpdateProgramMutation,
  UpdateProgramMutationVariables,
  UpdateProjectAssetMutation,
  UpdateProjectAssetMutationVariables,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
  UpdateProjectTaskMutation,
  UpdateProjectTaskMutationVariables,
  UpdateProjectTaskStatusMutation,
  UpdateProjectTaskStatusMutationVariables,
  UpdateProjectUserMutation,
  UpdateProjectUserMutationVariables,
  UpdateProjectWorkflowTemplateMutation,
  UpdateProjectWorkflowTemplateMutationVariables,
  UpdateRuleMutation,
  UpdateRuleMutationVariables,
  UpdateSlackDataChannelMutation,
  UpdateSlackDataChannelMutationVariables,
  UpdateSpaceMutation,
  UpdateSpaceMutationVariables,
  UpdateSpaceUserMutation,
  UpdateSpaceUserMutationVariables,
  UpdateSuggestionMutation,
  UpdateSuggestionMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables,
  UpdateWorkflowTemplateActiveStatusMutation,
  UpdateWorkflowTemplateActiveStatusMutationVariables,
  UpdateWorkflowTemplateMutation,
  UpdateWorkflowTemplateMutationVariables,
} from '../../dto-types'
import { graphql } from '../../dto-types/src/gql/gql'

export const UPDATE_USER_ROLE: TypedDocumentNode<UpdateUserRoleMutation, UpdateUserRoleMutationVariables> = graphql(`
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      id
      role
    }
  }
`)

export const UPDATE_USER: TypedDocumentNode<UpdateUserMutation, UpdateUserMutationVariables> = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      avatarUrl
    }
  }
`)

export const CREATE_ORGANIZATION: TypedDocumentNode<CreateOrganizationMutation, CreateOrganizationMutationVariables> =
  graphql(`
    mutation CreateOrganization($input: CreateOrganizationInput!) {
      createOrganization(input: $input) {
        ...OrganizationFields
        users {
          ...OrganizationUserFields
        }
        spaces {
          ...SpaceFields
          projects {
            ...ProjectFields
          }
        }
      }
    }
  `)

export const UPDATE_ORGANIZATION: TypedDocumentNode<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> =
  graphql(`
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
      updateOrganization(input: $input) {
        id
        name
        logoUrl
      }
    }
  `)

export const UPDATE_ORGANIZATION_METADATA: TypedDocumentNode<
  UpdateOrganizationMetadataMutation,
  UpdateOrganizationMetadataMutationVariables
> = graphql(`
  mutation UpdateOrganizationMetadata($input: UpdateOrganizationMetadataInput!) {
    updateOrganizationMetadata(input: $input) {
      id
      metadata
    }
  }
`)

export const UPDATE_DEFAULT_ORGANIZATION_USER: TypedDocumentNode<
  UpdateDefaultOrganizationUserMutation,
  UpdateDefaultOrganizationUserMutationVariables
> = graphql(`
  mutation UpdateDefaultOrganizationUser($input: UpdateDefaultOrganizationUserInput!) {
    updateDefaultUser(input: $input) {
      id
    }
  }
`)

export const DELETE_ORGANIZATION: TypedDocumentNode<DeleteOrganizationMutation, DeleteOrganizationMutationVariables> =
  graphql(`
    mutation DeleteOrganization($organizationId: String!) {
      deleteOrganization(organizationId: $organizationId)
    }
  `)

export const LEAVE_ORGANIZATION: TypedDocumentNode<LeaveOrganizationMutation, LeaveOrganizationMutationVariables> =
  graphql(`
    mutation LeaveOrganization($organizationId: String!) {
      leaveOrganization(organizationId: $organizationId)
    }
  `)

export const CREATE_SPACE: TypedDocumentNode<CreateSpaceMutation, CreateSpaceMutationVariables> = graphql(`
  mutation CreateSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      id
      name
      spaceUrl
    }
  }
`)

export const DELETE_SPACE: TypedDocumentNode<DeleteSpaceMutation, DeleteSpaceMutationVariables> = graphql(`
  mutation DeleteSpace($id: String!) {
    deleteSpace(id: $id)
  }
`)

export const ARCHIVE_SPACE: TypedDocumentNode<ArchiveSpaceMutation, ArchiveSpaceMutationVariables> = graphql(`
  mutation ArchiveSpace($input: ArchiveSpaceInput!) {
    archiveSpace(input: $input)
  }
`)

export const CREATE_PROJECT: TypedDocumentNode<CreateProjectMutation, CreateProjectMutationVariables> = graphql(`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      name
    }
  }
`)

export const DELETE_PROJECT: TypedDocumentNode<DeleteProjectMutation, DeleteProjectMutationVariables> = graphql(`
  mutation DeleteProject($id: String!) {
    deleteProject(id: $id)
  }
`)

export const ARCHIVE_PROJECT: TypedDocumentNode<ArchiveProjectMutation, ArchiveProjectMutationVariables> = graphql(`
  mutation ArchiveProject($id: String!) {
    archiveProject(id: $id)
  }
`)

export const CREATE_PROJECT_TASK: TypedDocumentNode<CreateProjectTaskMutation, CreateProjectTaskMutationVariables> =
  graphql(`
    mutation CreateProjectTask($input: CreateProjectTaskInput!) {
      createProjectTask(input: $input) {
        id
        asset {
          id
          name
        }
        project {
          projectUrl
          space {
            spaceUrl
          }
        }
      }
    }
  `)

export const UPDATE_PROJECT_TASK: TypedDocumentNode<UpdateProjectTaskMutation, UpdateProjectTaskMutationVariables> =
  graphql(`
    mutation UpdateProjectTask($input: UpdateProjectTaskInput!) {
      updateProjectTask(input: $input) {
        id
        reviewer {
          id
          firstName
          lastName
          avatarUrl
        }
        dueDate
        priority
      }
    }
  `)

export const ARCHIVE_PROJECT_TASK: TypedDocumentNode<ArchiveProjectTaskMutation, ArchiveProjectTaskMutationVariables> =
  graphql(`
    mutation ArchiveProjectTask($id: String!) {
      archiveProjectTask(id: $id) {
        id
      }
    }
  `)

export const CREATE_PROJECT_TASK_ATTACHMENTS: TypedDocumentNode<
  CreateProjectTaskAttachmentsMutation,
  CreateProjectTaskAttachmentsMutationVariables
> = graphql(`
  mutation createProjectTaskAttachments($input: [CreateTaskAttachmentInput!]!) {
    createProjectTaskAttachments(input: $input) {
      id
    }
  }
`)

export const CREATE_ACTIVITY_TASK_ATTACHMENTS: TypedDocumentNode<
  CreateActivityTaskAttachmentsMutation,
  CreateActivityTaskAttachmentsMutationVariables
> = graphql(`
  mutation CreateActivityTaskAttachments($input: [CreateTaskAttachmentInput!]!) {
    createActivityTaskAttachments(input: $input) {
      id
    }
  }
`)

export const UPDATE_PROJECT_ASSET: TypedDocumentNode<UpdateProjectAssetMutation, UpdateProjectAssetMutationVariables> =
  graphql(`
    mutation updateProjectAsset($input: UpdateProjectAssetInput!) {
      updateProjectAsset(input: $input) {
        id
        state
      }
    }
  `)

export const UPDATE_PROJECT_TASK_STATUS: TypedDocumentNode<
  UpdateProjectTaskStatusMutation,
  UpdateProjectTaskStatusMutationVariables
> = graphql(`
  mutation UpdateProjectTaskStatus($input: UpdateProjectTaskStatusInput!) {
    updateProjectTaskStatus(input: $input) {
      id
      status
    }
  }
`)

export const BUMP_ASSET_VERSION: TypedDocumentNode<BumpAssetVersionMutation, BumpAssetVersionMutationVariables> =
  graphql(`
    mutation BumpAssetVersion($input: BumpAssetVersionInput!) {
      bumpAssetVersion(input: $input) {
        id
      }
    }
  `)

export const CREATE_OR_UPDATE_DOCUMENT_ASSET_REVISIONS: TypedDocumentNode<
  CreateOrUpdateDocumentAssetRevisionsMutation,
  CreateOrUpdateDocumentAssetRevisionsMutationVariables
> = graphql(`
  mutation CreateOrUpdateDocumentAssetRevisions($input: COUDocumentAssetRevisionInput!) {
    createOrUpdateDocumentAssetRevisions(input: $input) {
      id
      externalId
      name
      createdAt
      creatorId
      authorsIds
      attributes
      fromVersion
      toVersion
      diffData
      updatedAt
    }
  }
`)

export const CREATE_ASSET_REVIEW_REQUEST: TypedDocumentNode<
  CreateAssetReviewRequestMutation,
  CreateAssetReviewRequestMutationVariables
> = graphql(`
  mutation CreateAssetReviewRequest($input: CreateReviewRequestInput!) {
    reviewRequest(input: $input) {
      id
    }
  }
`)

export const UPDATE_ASSET_REVIEW_REQUEST: TypedDocumentNode<
  UpdateAssetReviewRequestMutation,
  UpdateAssetReviewRequestMutationVariables
> = graphql(`
  mutation UpdateAssetReviewRequest($input: UpdateReviewRequestInput!) {
    updateReviewRequest(input: $input) {
      id
      status
    }
  }
`)

export const INVITE_ORGANIZATION_MEMBERS: TypedDocumentNode<
  InviteOrganizationMembersMutation,
  InviteOrganizationMembersMutationVariables
> = graphql(`
  mutation InviteOrganizationMembers($input: CreateOrganizationInvitationsInput!) {
    createOrganizationInvitations(input: $input) {
      failedItems {
        email
        message
      }
    }
  }
`)

export const INVITE_ORGANIZATION_MEMBER: TypedDocumentNode<
  InviteOrganizationMemberMutation,
  InviteOrganizationMemberMutationVariables
> = graphql(`
  mutation InviteOrganizationMember($input: CreateOrganizationInvitationInput!) {
    createOrganizationInvitation(input: $input) {
      id
      email
      role
    }
  }
`)

export const CANCEL_ORGANIZATION_INVITATION: TypedDocumentNode<
  CancelInvitationMutation,
  CancelInvitationMutationVariables
> = graphql(`
  mutation CancelInvitation($input: CancelOrganizationInvitationInput!) {
    cancelOrganizationInvitation(input: $input)
  }
`)

export const ACCEPT_ORGANIZATION_INVITATION: TypedDocumentNode<
  AcceptOrganizationInvitationMutation,
  AcceptOrganizationInvitationMutationVariables
> = graphql(`
  mutation AcceptOrganizationInvitation($input: AcceptOrganizationInvitationInput!) {
    acceptOrganizationInvitation(input: $input) {
      id
      email
      role
      organization {
        name
        teamUrl
      }
    }
  }
`)

export const INVITE_PROJECT_MEMBERS: TypedDocumentNode<
  InviteProjectMembersMutation,
  InviteProjectMembersMutationVariables
> = graphql(`
  mutation InviteProjectMembers($input: CreateProjectInvitationsInput!) {
    createProjectInvitations(input: $input) {
      failedItems {
        email
        message
      }
    }
  }
`)

export const INVITE_SPACE_MEMBERS: TypedDocumentNode<InviteSpaceMembersMutation, InviteSpaceMembersMutationVariables> =
  graphql(`
    mutation InviteSpaceMembers($input: CreateSpaceInvitationsInput!) {
      createSpaceInvitations(input: $input) {
        failedItems {
          email
          message
        }
      }
    }
  `)

export const ACCEPT_SPACE_INVITATION: TypedDocumentNode<
  AcceptSpaceInvitationMutation,
  AcceptSpaceInvitationMutationVariables
> = graphql(`
  mutation AcceptSpaceInvitation($uniqueId: String!) {
    acceptSpaceInvitation(uniqueId: $uniqueId) {
      id
      email
      role
      space {
        name
        spaceUrl
        isOnboarded
        organization {
          organizationProducts {
            product {
              id
              name
            }
          }
          teamUrl
        }
      }
    }
  }
`)

export const ACCEPT_PROJECT_INVITATION: TypedDocumentNode<
  AcceptProjectInvitationMutation,
  AcceptProjectInvitationMutationVariables
> = graphql(`
  mutation AcceptProjectInvitation($uniqueId: String!) {
    acceptProjectInvitation(uniqueId: $uniqueId) {
      id
      email
      role
      project {
        name
        projectUrl
        space {
          spaceUrl
          organization {
            teamUrl
          }
        }
      }
    }
  }
`)

export const CANCEL_PROJECT_INVITATION: TypedDocumentNode<
  CancelProjectInvitationMutation,
  CancelProjectInvitationMutationVariables
> = graphql(`
  mutation CancelProjectInvitation($uniqueId: String!) {
    cancelProjectInvitation(uniqueId: $uniqueId)
  }
`)

export const CANCEL_SPACE_INVITATION: TypedDocumentNode<
  CancelSpaceInvitationMutation,
  CancelSpaceInvitationMutationVariables
> = graphql(`
  mutation CancelSpaceInvitation($uniqueId: String!) {
    cancelSpaceInvitation(uniqueId: $uniqueId)
  }
`)

export const CREATE_WORKFLOW_TEMPLATE: TypedDocumentNode<
  CreateWorkflowTemplateMutation,
  CreateWorkflowTemplateMutationVariables
> = graphql(`
  mutation CreateWorkflowTemplate($input: CreateWorkflowTemplateInput!) {
    createWorkflowTemplate(input: $input) {
      id
      name
      nodes {
        payload
        children {
          id
          name
        }
        createdAt
        deletedAt
        id
        name
        type
        updatedAt
      }
    }
  }
`)

export const UPDATE_WORKFLOW_TEMPLATE: TypedDocumentNode<
  UpdateWorkflowTemplateMutation,
  UpdateWorkflowTemplateMutationVariables
> = graphql(`
  mutation UpdateWorkflowTemplate($input: UpdateWorkflowTemplateInput!) {
    updateWorkflowTemplate(input: $input) {
      id
      name
      nodes {
        payload
        children {
          id
          name
        }
        createdAt
        deletedAt
        id
        name
        type
        updatedAt
      }
    }
  }
`)

export const CREATE_PROJECT_WORKFLOW_TEMPLATE: TypedDocumentNode<
  CreateProjectWorkflowTemplateMutation,
  CreateProjectWorkflowTemplateMutationVariables
> = graphql(`
  mutation CreateProjectWorkflowTemplate($input: CreateProjectWorkflowTemplateInput!) {
    createProjectWorkflowTemplate(input: $input) {
      id
      name
      nodes {
        payload
        children {
          id
          name
        }
        createdAt
        deletedAt
        id
        name
        type
        updatedAt
      }
    }
  }
`)

export const UPDATE_PROJECT_WORKFLOW_TEMPLATE: TypedDocumentNode<
  UpdateProjectWorkflowTemplateMutation,
  UpdateProjectWorkflowTemplateMutationVariables
> = graphql(`
  mutation UpdateProjectWorkflowTemplate($input: UpdateProjectWorkflowTemplateInput!) {
    updateProjectWorkflowTemplate(input: $input) {
      id
      name
      nodes {
        payload
        children {
          id
          name
        }
        createdAt
        deletedAt
        id
        name
        type
        updatedAt
      }
    }
  }
`)

export const DELETE_WORKFLOW_TEMPLATE: TypedDocumentNode<
  DeleteWorkflowTemplateMutation,
  DeleteWorkflowTemplateMutationVariables
> = graphql(`
  mutation DeleteWorkflowTemplate($id: String!) {
    deleteWorkflowTemplate(id: $id)
  }
`)

export const ARCHIVE_WORKFLOW_TEMPLATE: TypedDocumentNode<
  ArchiveWorkflowTemplateMutation,
  ArchiveWorkflowTemplateMutationVariables
> = graphql(`
  mutation ArchiveWorkflowTemplate($id: String!) {
    archiveWorkflowTemplate(id: $id)
  }
`)

export const UPDATE_WORKFLOW_TEMPLATE_ACTIVE_STATUS: TypedDocumentNode<
  UpdateWorkflowTemplateActiveStatusMutation,
  UpdateWorkflowTemplateActiveStatusMutationVariables
> = graphql(`
  mutation UpdateWorkflowTemplateActiveStatus($id: String!, $active: Boolean!) {
    updateWorkflowTemplateActiveStatus(id: $id, active: $active)
  }
`)

export const UPDATE_SPACE: TypedDocumentNode<UpdateSpaceMutation, UpdateSpaceMutationVariables> = graphql(`
  mutation UpdateSpace($id: String!, $input: UpdateSpaceInput!) {
    updateSpace(id: $id, input: $input) {
      id
      name
      description
    }
  }
`)

export const UPDATE_SPACE_USER: TypedDocumentNode<UpdateSpaceUserMutation, UpdateSpaceUserMutationVariables> = graphql(`
  mutation UpdateSpaceUser($id: String!, $input: UpdateSpaceUserInput!) {
    updateSpaceUser(id: $id, input: $input) {
      id
      role
    }
  }
`)

export const CREATE_COMMENT_THREAD: TypedDocumentNode<
  CreateCommentThreadMutation,
  CreateCommentThreadMutationVariables
> = graphql(`
  mutation CreateCommentThread($input: CreateCommentThreadInput!) {
    createCommentThread(input: $input) {
      id
      externalId
      attributes
      context
      resolvedAt
      resolvedBy {
        id
      }
      comments {
        id
        externalId
        content
        createdAt
        author {
          id
        }
        attributes
      }
    }
  }
`)

export const UPDATE_COMMENT_THREAD: TypedDocumentNode<
  UpdateCommentThreadMutation,
  UpdateCommentThreadMutationVariables
> = graphql(`
  mutation UpdateCommentThread($input: UpdateCommentThreadInput!) {
    updateCommentThread(input: $input) {
      id
      externalId
      attributes
      context
      resolvedAt
      resolvedBy {
        id
      }
      comments {
        id
        externalId
        content
        createdAt
        author {
          id
        }
        attributes
      }
    }
  }
`)

export const DELETE_COMMENT_THREAD: TypedDocumentNode<
  DeleteCommentThreadMutation,
  DeleteCommentThreadMutationVariables
> = graphql(`
  mutation DeleteCommentThread($input: DeleteCommentThreadInput!) {
    deleteCommentThread(input: $input)
  }
`)

export const CREATE_COMMENT: TypedDocumentNode<CreateCommentMutation, CreateCommentMutationVariables> = graphql(`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      attributes
      author {
        id
      }
      content
      createdAt
      externalId
      id
    }
  }
`)

export const UPDATE_COMMENT: TypedDocumentNode<UpdateCommentMutation, UpdateCommentMutationVariables> = graphql(`
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      attributes
      author {
        id
      }
      content
      createdAt
      externalId
      id
    }
  }
`)

export const DELETE_COMMENT: TypedDocumentNode<DeleteCommentMutation, DeleteCommentMutationVariables> = graphql(`
  mutation DeleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input)
  }
`)

export const ADVANCE_WORKFLOW: TypedDocumentNode<AdvanceWorkflowMutation, AdvanceWorkflowMutationVariables> = graphql(`
  mutation AdvanceWorkflow($taskId: String!) {
    advanceWorkflow(taskId: $taskId) {
      id
      name
      status
    }
  }
`)

export const UPDATE_NOTIFICATION_PREFERENCES_PROJECT: TypedDocumentNode<
  UpdateNotificationPreferencesProjectMutation,
  UpdateNotificationPreferencesProjectMutationVariables
> = graphql(`
  mutation UpdateNotificationPreferencesProject($input: UpdateNotificationPreferencesProjectInput!) {
    updateNotificationPreferencesProject(input: $input) {
      id
      projectId
      userId
      notifyTasks
      notifyComments
      notifyStatusUpdates
      notifyStatusFrequency
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const UPDATE_NOTIFICATION_PREFERENCES_SPACE: TypedDocumentNode<
  UpdateNotificationPreferencesSpaceMutation,
  UpdateNotificationPreferencesSpaceMutationVariables
> = graphql(`
  mutation UpdateNotificationPreferencesSpace($input: UpdateNotificationPreferencesSpaceInput!) {
    updateNotificationPreferencesSpace(input: $input) {
      id
      spaceId
      userId
      notifyAddedToProject
      notifyProjectCreated
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const UPDATE_SLACK_DATA_CHANNEL: TypedDocumentNode<
  UpdateSlackDataChannelMutation,
  UpdateSlackDataChannelMutationVariables
> = graphql(`
  mutation UpdateSlackDataChannel($input: UpdateSlackDataChannelInput!) {
    updateSlackDataChannel(input: $input) {
      organizationId
      collection
      objectId
      id
      name
    }
  }
`)

export const DELETE_SLACK_DATA_CHANNEL: TypedDocumentNode<
  DeleteSlackDataChannelMutation,
  DeleteSlackDataChannelMutationVariables
> = graphql(`
  mutation DeleteSlackDataChannel($input: DeleteSlackDataChannelInput!) {
    deleteSlackDataChannel(input: $input)
  }
`)

export const CREATE_NOTIFICATION_PREFERENCES_USER: TypedDocumentNode<
  CreateNotificationPreferencesUserMutation,
  CreateNotificationPreferencesUserMutationVariables
> = graphql(`
  mutation CreateNotificationPreferencesUser($input: CreateNotificationPreferencesUserInput!) {
    createNotificationPreferencesUser(input: $input) {
      id
      userId
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const UPDATE_NOTIFICATION_PREFERENCES_USER: TypedDocumentNode<
  UpdateNotificationPreferencesUserMutation,
  UpdateNotificationPreferencesUserMutationVariables
> = graphql(`
  mutation UpdateNotificationPreferencesUser($input: UpdateNotificationPreferencesUserInput!) {
    updateNotificationPreferencesUser(input: $input) {
      id
      userId
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const LEAVE_SPACE: TypedDocumentNode<LeaveSpaceMutation, LeaveSpaceMutationVariables> = graphql(`
  mutation LeaveSpace($id: String!) {
    leaveSpace(id: $id)
  }
`)

export const JOIN_SPACE: TypedDocumentNode<JoinSpaceMutation, JoinSpaceMutationVariables> = graphql(`
  mutation JoinSpace($id: String!) {
    joinSpace(id: $id) {
      id
    }
  }
`)

export const UPDATE_ORGANIZATION_USER: TypedDocumentNode<
  UpdateOrganizationUserMutation,
  UpdateOrganizationUserMutationVariables
> = graphql(`
  mutation UpdateOrganizationUser($input: UpdateOrganizationUserInput!) {
    updateOrganizationUser(input: $input) {
      id
    }
  }
`)

export const UPDATE_PROJECT_USER: TypedDocumentNode<UpdateProjectUserMutation, UpdateProjectUserMutationVariables> =
  graphql(`
    mutation UpdateProjectUser($id: String!, $input: UpdateProjectUserInput!) {
      updateProjectUser(id: $id, input: $input) {
        id
        role
      }
    }
  `)

export const UPDATE_PROJECT: TypedDocumentNode<UpdateProjectMutation, UpdateProjectMutationVariables> = graphql(`
  mutation UpdateProject($id: String!, $input: UpdateProjectInput!) {
    updateProject(id: $id, input: $input) {
      id
      name
      description
      projectUrl
    }
  }
`)

export const LEAVE_PROJECT: TypedDocumentNode<LeaveProjectMutation, LeaveProjectMutationVariables> = graphql(`
  mutation LeaveProject($id: String!) {
    leaveProject(id: $id)
  }
`)

export const DELETE_INTEGRATION: TypedDocumentNode<DeleteIntegrationMutation, DeleteIntegrationMutationVariables> =
  graphql(`
    mutation DeleteIntegration($input: DeleteIntegrationInput!) {
      deleteIntegration(input: $input)
    }
  `)

export const CREATE_PROJECT_TASK_ATTACHMENT_UPLOAD_URL: TypedDocumentNode<
  CreateProjectTaskAttachmentUploadUrlMutation,
  CreateProjectTaskAttachmentUploadUrlMutationVariables
> = graphql(`
  mutation createProjectTaskAttachmentUploadUrl($input: CreateTaskAttachmentUploadUrlInput!) {
    createProjectTaskAttachmentUploadUrl(input: $input) {
      signedUrl
      fileKey
    }
  }
`)

export const CREATE_ACTIVITY_TASK_ATTACHMENT_UPLOAD_URL: TypedDocumentNode<
  CreateActivityTaskAttachmentUploadUrlMutation,
  CreateActivityTaskAttachmentUploadUrlMutationVariables
> = graphql(`
  mutation CreateActivityTaskAttachmentUploadUrl($input: CreateTaskAttachmentUploadUrlInput!) {
    createActivityTaskAttachmentUploadUrl(input: $input) {
      signedUrl
      fileKey
    }
  }
`)

export const UPDATE_SUGGESTION: TypedDocumentNode<UpdateSuggestionMutation, UpdateSuggestionMutationVariables> =
  graphql(`
    mutation updateSuggestion($input: ResolveSuggestionInput!) {
      updateSuggestion(input: $input)
    }
  `)

export const GENERATE_SUGGESTIONS_FOR_ASSET: TypedDocumentNode<
  QueueSuggestionsJobForAssetMutation,
  QueueSuggestionsJobForAssetMutationVariables
> = graphql(`
  mutation queueSuggestionsJobForAsset($input: UpdateAssetWithSuggestionsInput!) {
    queueSuggestionsJobForAsset(input: $input)
  }
`)

export const IGNORE_SUGGESTIONS_FOR_ASSET: TypedDocumentNode<
  IgnoreSuggestionsForAssetMutation,
  IgnoreSuggestionsForAssetMutationVariables
> = graphql(`
  mutation ignoreSuggestionsForAsset($assetId: String!) {
    ignoreSuggestionsForAsset(assetId: $assetId)
  }
`)

export const GET_OR_CREATE_LIVEBLOCKS_ROOM: TypedDocumentNode<
  GetOrCreateLiveblocksRoomMutation,
  GetOrCreateLiveblocksRoomMutationVariables
> = graphql(`
  mutation GetOrCreateLiveblocksRoom($input: CreateRoomForAssetInput!) {
    getOrCreateLiveblocksRoomForAsset(input: $input) {
      id
      createdAt
      lastConnectionAt
    }
  }
`)

export const CREATE_PROGRAM: TypedDocumentNode<CreateProgramMutation, CreateProgramMutationVariables> = graphql(`
  mutation CreateProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      id
    }
  }
`)

export const UPDATE_PROGRAM: TypedDocumentNode<UpdateProgramMutation, UpdateProgramMutationVariables> = graphql(`
  mutation UpdateProgram($id: String!, $input: UpdateProgramInput!) {
    updateProgram(id: $id, input: $input) {
      id
    }
  }
`)

export const DELETE_PROGRAM: TypedDocumentNode<DeleteProgramMutation, DeleteProgramMutationVariables> = graphql(`
  mutation DeleteProgram($id: String!) {
    deleteProgram(id: $id)
  }
`)

export const CREATE_PARTNERSHIP: TypedDocumentNode<CreatePartnershipMutation, CreatePartnershipMutationVariables> =
  graphql(`
    mutation CreatePartnership($input: CreatePartnershipInput!) {
      createPartnership(input: $input) {
        id
        sponsorOrganizationId
        partnerOrganizationId
      }
    }
  `)

export const UPDATE_PARTNERSHIP: TypedDocumentNode<UpdatePartnershipMutation, UpdatePartnershipMutationVariables> =
  graphql(`
    mutation UpdatePartnership($input: UpdatePartnershipInput!) {
      updatePartnership(input: $input) {
        id
        sponsorOrganizationId
        partnerOrganizationId
        onboardingStartTime
        onboardingFinishTime
      }
    }
  `)

export const DELETE_PARTNERSHIP: TypedDocumentNode<DeletePartnershipMutation, DeletePartnershipMutationVariables> =
  graphql(`
    mutation DeletePartnership($input: DeletePartnershipInput!) {
      deletePartnership(input: $input)
    }
  `)

export const CREATE_MOSAIC_PARTNERSHIP: TypedDocumentNode<
  CreateMosaicPartnershipMutation,
  CreateMosaicPartnershipMutationVariables
> = graphql(`
  mutation CreateMosaicPartnership($input: CreateMosaicPartnershipInput!) {
    createMosaicPartnership(input: $input) {
      id
      sponsorOrganizationId
      partnerOrganizationId
    }
  }
`)

export const INITIATE_PARTNER_ONBOARDING: TypedDocumentNode<
  InitiatePartnerOnboardingMutation,
  InitiatePartnerOnboardingMutationVariables
> = graphql(`
  mutation initiatePartnerOnboarding($url: String!, $partnershipId: String!) {
    initiatePartnerOnboarding(url: $url, partnershipId: $partnershipId) {
      organization {
        metadata {
          legalNames
        }
      }
      licences {
        metadata {
          legalName
        }
      }
      links {
        href
      }
      notableNews {
        metadata {
          snippet
        }
      }
    }
  }
`)

export const CREATE_PROCESS: TypedDocumentNode<CreateProcessMutation, CreateProcessMutationVariables> = graphql(`
  mutation CreateProcess($input: CreateProcessInput!) {
    createProcess(input: $input) {
      ...ProcessFields
    }
  }
`)

export const UPDATE_PROCESS: TypedDocumentNode<UpdateProcessMutation, UpdateProcessMutationVariables> = graphql(`
  mutation UpdateProcess($input: UpdateProcessInput!) {
    updateProcess(input: $input) {
      ...ProcessFields
    }
  }
`)

export const CREATE_PROCESS_MEMBERS: TypedDocumentNode<
  CreateProcessMembersMutation,
  CreateProcessMembersMutationVariables
> = graphql(`
  mutation CreateProcessMembers($input: CreateProcessMembersInput!) {
    createProcessMembers(input: $input) {
      ...ProcessMemberFields
    }
  }
`)

export const SAVE_PROCESS_FIELDS: TypedDocumentNode<SaveProcessFieldsMutation, SaveProcessFieldsMutationVariables> =
  graphql(`
    mutation SaveProcessFields($input: [CreateProcessFieldInput!]!, $processId: String!) {
      saveProcessFields(input: $input, processId: $processId) {
        ...ProcessFieldFields
      }
    }
  `)

export const DELETE_PROCESS_FIELDS: TypedDocumentNode<
  DeleteProcessFieldsMutation,
  DeleteProcessFieldsMutationVariables
> = graphql(`
  mutation DeleteProcessFields($id: String!) {
    removeProcessField(id: $id)
  }
`)

export const SET_PROCESS_MEMBERS: TypedDocumentNode<SetProcessMembersMutation, SetProcessMembersMutationVariables> =
  graphql(`
    mutation SetProcessMembers($input: CreateProcessMembersInput!) {
      setProcessMembers(input: $input) {
        ...ProcessMemberFields
      }
    }
  `)

export const CREATE_ISSUES_FOR_PARTNER_SOURCE: TypedDocumentNode<
  CreateIssuesForPartnerSourceMutation,
  CreateIssuesForPartnerSourceMutationVariables
> = graphql(`
  mutation CreateIssuesForPartnerSource($partnerSourceId: String!) {
    createIssuesForPartnerSource(partnerSourceId: $partnerSourceId) {
      id
    }
  }
`)

export const CREATE_ISSUES_FOR_PARTNERSHIP: TypedDocumentNode<
  CreateIssuesForPartnershipMutation,
  CreateIssuesForPartnershipMutationVariables
> = graphql(`
  mutation CreateIssuesForPartnership($partnershipId: String!) {
    createIssuesForPartnership(partnershipId: $partnershipId) {
      id
    }
  }
`)

export const CREATE_PARTNER_SOURCES: TypedDocumentNode<
  CreatePartnerSourcesMutation,
  CreatePartnerSourcesMutationVariables
> = graphql(`
  mutation CreatePartnerSources($input: CreatePartnerSourcesInput!) {
    createPartnerSources(input: $input) {
      id
    }
  }
`)

export const CREATE_PARTNER_SOURCE: TypedDocumentNode<
  CreatePartnerSourceMutation,
  CreatePartnerSourceMutationVariables
> = graphql(`
  mutation CreatePartnerSource($input: CreatePartnerSourceInput!) {
    createPartnerSource(input: $input) {
      id
    }
  }
`)

export const UPDATE_PARTNER_SOURCE: TypedDocumentNode<
  UpdatePartnerSourceMutation,
  UpdatePartnerSourceMutationVariables
> = graphql(`
  mutation UpdatePartnerSource($input: UpdatePartnerSourceInput!) {
    updatePartnerSource(input: $input) {
      id
    }
  }
`)

export const DELETE_PARTNER_SOURCE: TypedDocumentNode<
  DeletePartnerSourceMutation,
  DeletePartnerSourceMutationVariables
> = graphql(`
  mutation DeletePartnerSource($partnerSourceId: String!) {
    deletePartnerSource(partnerSourceId: $partnerSourceId) {
      id
    }
  }
`)

// this goes to the monitoring resolver
export const RUN_PARTNER_SOURCE: TypedDocumentNode<
  RunSinglePartnerSourceMutation,
  RunSinglePartnerSourceMutationVariables
> = graphql(`
  mutation RunSinglePartnerSource($partnerSourceId: String!) {
    runSinglePartnerSource(partnerSourceId: $partnerSourceId)
  }
`)

export const DELETE_ISSUE: TypedDocumentNode<DeleteIssueMutation, DeleteIssueMutationVariables> = graphql(`
  mutation DeleteIssue($issueId: String!) {
    deleteIssue(issueId: $issueId) {
      id
    }
  }
`)

export const CREATE_RULE: TypedDocumentNode<CreateRuleMutation, CreateRuleMutationVariables> = graphql(`
  mutation CreateRule($input: CreateRuleInput!) {
    createRule(input: $input) {
      id
    }
  }
`)

export const UPDATE_RULE: TypedDocumentNode<UpdateRuleMutation, UpdateRuleMutationVariables> = graphql(`
  mutation UpdateRule($input: UpdateRuleInput!) {
    updateRule(input: $input) {
      id
    }
  }
`)

export const UPDATE_ACTIVITY: TypedDocumentNode<UpdateActivityMutation, UpdateActivityMutationVariables> = graphql(`
  mutation UpdateActivity($id: String!, $input: UpdateActivityInput!) {
    updateActivity(id: $id, input: $input) {
      id
    }
  }
`)

export const DELETE_ACTIVITY: TypedDocumentNode<DeleteActivityMutation, DeleteActivityMutationVariables> = graphql(`
  mutation DeleteActivity($id: String!) {
    deleteActivity(id: $id)
  }
`)

export const DELETE_ACTIVITIES: TypedDocumentNode<DeleteActivitiesMutation, DeleteActivitiesMutationVariables> =
  graphql(`
    mutation DeleteActivities($ids: [String!]!) {
      deleteActivities(ids: $ids)
    }
  `)

export const CREATE_ACTIVITY: TypedDocumentNode<CreateActivityMutation, CreateActivityMutationVariables> = graphql(`
  mutation CreateActivity($input: CreateActivityInput!) {
    createActivity(input: $input) {
      id
    }
  }
`)

export const DELETE_MOSAIC_PARTNERSHIP: TypedDocumentNode<
  DeleteMosaicPartnershipMutation,
  DeleteMosaicPartnershipMutationVariables
> = graphql(`
  mutation DeleteMosaicPartnership($input: DeletePartnershipInput!) {
    deleteMosaicPartnership(input: $input)
  }
`)

export const DELETE_ACTIVITY_SUBSCRIPTION: TypedDocumentNode<
  DeleteActivitySubscriptionMutation,
  DeleteActivitySubscriptionMutationVariables
> = graphql(`
  mutation DeleteActivitySubscription($id: String!) {
    deleteActivitySubscription(id: $id)
  }
`)

export const DELETE_ACTIVITY_SUBSCRIPTION_CASCADE: TypedDocumentNode<
  DeleteActivitySubscriptionCascadeMutation,
  DeleteActivitySubscriptionCascadeMutationVariables
> = graphql(`
  mutation DeleteActivitySubscriptionCascade($id: String!, $cascade: Boolean!) {
    deleteActivitySubscriptionCascade(id: $id, cascade: $cascade)
  }
`)

export const CREATE_ACTIVITY_SUBSCRIPTION: TypedDocumentNode<
  CreateActivitySubscriptionMutation,
  CreateActivitySubscriptionMutationVariables
> = graphql(`
  mutation CreateActivitySubscription($input: CreateActivitySubscriptionInput!) {
    createActivitySubscription(input: $input) {
      id
      activity {
        id
      }
      organization {
        id
      }
      sourceBank {
        id
      }
      description
      status
      inheritedFromBank
      optedOut
      createdAt
      updatedAt
      deletedAt
    }
  }
`)

export const CREATE_BANK_ACTIVITY_SUBSCRIPTION: TypedDocumentNode<
  CreateBankSubscriptionMutation,
  CreateBankSubscriptionMutationVariables
> = graphql(`
  mutation CreateBankSubscription($input: CreateBankSubscriptionInput!) {
    createBankSubscription(input: $input) {
      id
      activity {
        id
      }
      organization {
        id
      }
      sourceBank {
        id
      }
      description
      status
      inheritedFromBank
      optedOut
      createdAt
      updatedAt
      deletedAt
    }
  }
`)

export const ADD_ACTIVITY_TASK_COMMENT: TypedDocumentNode<
  AddActivityTaskCommentMutation,
  AddActivityTaskCommentMutationVariables
> = graphql(`
  mutation AddActivityTaskComment($input: AddActivityTaskCommentInput!) {
    addCommentToActivityTask(input: $input) {
      id
      content
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
      }
    }
  }
`)

export const ADD_ASSET_TO_ACTIVITY_TASK: TypedDocumentNode<
  AddAssetToActivityTaskMutation,
  AddAssetToActivityTaskMutationVariables
> = graphql(`
  mutation AddAssetToActivityTask($input: AddAssetToActivityTaskInput!) {
    addAssetToActivityTask(input: $input) {
      id
    }
  }
`)

export const DELETE_ASSET_FROM_ACTIVITY_TASK: TypedDocumentNode<
  DeleteAssetFromActivityTaskMutation,
  DeleteAssetFromActivityTaskMutationVariables
> = graphql(`
  mutation DeleteAssetFromActivityTask($input: DeleteAssetFromActivityTaskInput!) {
    deleteAssetFromActivityTask(input: $input) {
      id
    }
  }
`)

export const APPROVE_ACTIVITY_TASK: TypedDocumentNode<
  ApproveActivityTaskMutation,
  ApproveActivityTaskMutationVariables
> = graphql(`
  mutation ApproveActivityTask($input: ApproveActivityTaskInput!) {
    approveActivityTask(input: $input) {
      id
    }
  }
`)

export const SUBMIT_ACTIVITY_TASK: TypedDocumentNode<SubmitActivityTaskMutation, SubmitActivityTaskMutationVariables> =
  graphql(`
    mutation SubmitActivityTask($input: SubmitActivityTaskInput!) {
      submitActivityTask(input: $input) {
        id
      }
    }
  `)

export const REQUEST_CHANGES_ACTIVITY_TASK: TypedDocumentNode<
  RequestChangesToActivityTaskMutation,
  RequestChangesToActivityTaskMutationVariables
> = graphql(`
  mutation RequestChangesToActivityTask($input: RequestChangesToActivityTaskInput!) {
    requestChangesToActivityTask(input: $input) {
      id
    }
  }
`)

export const UPDATE_ACTIVITY_TASK_ASSIGNEE: TypedDocumentNode<
  UpdateActivityTaskAssigneeMutation,
  UpdateActivityTaskAssigneeMutationVariables
> = graphql(`
  mutation UpdateActivityTaskAssignee($input: UpdateActivityTaskAssigneeInput!) {
    updateActivityTaskAssignee(input: $input) {
      id
    }
  }
`)

export const UPDATE_ACTIVITY_TASK_STATUS: TypedDocumentNode<
  UpdateActivityTaskStatusMutation,
  UpdateActivityTaskStatusMutationVariables
> = graphql(`
  mutation UpdateActivityTaskStatus($input: UpdateActivityTaskStatusInput!) {
    updateActivityTaskStatus(input: $input) {
      id
    }
  }
`)

export const UPDATE_ACTIVITY_TASK_DUE_DATE: TypedDocumentNode<
  UpdateActivityTaskDueDateMutation,
  UpdateActivityTaskDueDateMutationVariables
> = graphql(`
  mutation UpdateActivityTaskDueDate($input: UpdateActivityTaskDueDateInput!) {
    updateActivityTaskDueDate(input: $input) {
      id
    }
  }
`)

export const LIFECYCLE_MARK_OUT_OF_SCOPE: TypedDocumentNode<MarkOutOfScopeMutation, MarkOutOfScopeMutationVariables> =
  graphql(`
    mutation MarkOutOfScope($input: MarkOutOfScopeInput!) {
      markOutOfScope(input: $input)
    }
  `)

export const CREATE_ACTIVITY_TASK_FROM_SUBSCRIPTION: TypedDocumentNode<
  CreateActivityTaskFromSubscriptionMutation,
  CreateActivityTaskFromSubscriptionMutationVariables
> = graphql(`
  mutation CreateActivityTaskFromSubscription($input: CreateActivityTaskFromSubscriptionIdInput!) {
    createActivityTaskFromSubscription(input: $input) {
      id
    }
  }
`)

export const SEND_LOG_ANALYSIS_INSIGHTS_CHAT_MESSAGE: TypedDocumentNode<
  SendLogAnalysisInsightsChatMessageMutation,
  SendLogAnalysisInsightsChatMessageMutationVariables
> = graphql(`
  mutation SendLogAnalysisInsightsChatMessage($input: EnrichedFragmentQAInput!) {
    enrichmentQA(input: $input) {
      output
    }
  }
`)

export const DELETE_ASSET: TypedDocumentNode<DeleteAssetMutation, DeleteAssetMutationVariables> = graphql(`
  mutation DeleteAsset($id: String!) {
    deleteAsset(id: $id)
  }
`)

export const PUBLISH_PROJECT_FORM: TypedDocumentNode<PublishProjectFormMutation, PublishProjectFormMutationVariables> =
  graphql(`
    mutation PublishProjectForm($id: String!) {
      publishProjectForm(id: $id) {
        id
        name
        publicFormHash
      }
    }
  `)

export const UNPUBLISH_PROJECT_FORM: TypedDocumentNode<
  UnpublishProjectFormMutation,
  UnpublishProjectFormMutationVariables
> = graphql(`
  mutation UnpublishProjectForm($id: String!) {
    unpublishProjectForm(id: $id) {
      id
      name
      publicFormHash
    }
  }
`)

export const CREATE_API_CREDENTIALS: TypedDocumentNode<
  CreateApiCredentialMutation,
  CreateApiCredentialMutationVariables
> = graphql(`
  mutation CreateApiCredential($input: ApiCredentialsInput!) {
    createApiCredential(input: $input) {
      apiKey
    }
  }
`)

export const REGENERATE_API_CREDENTIALS: TypedDocumentNode<
  RegenerateApiCredentialMutation,
  RegenerateApiCredentialMutationVariables
> = graphql(`
  mutation RegenerateApiCredential($input: ApiCredentialsInput!) {
    regenerateApiCredential(input: $input) {
      apiKey
    }
  }
`)

export const DELETE_API_CREDENTIALS: TypedDocumentNode<
  DeleteApiCredentialMutation,
  DeleteApiCredentialMutationVariables
> = graphql(`
  mutation DeleteApiCredential($input: ApiCredentialsInput!) {
    deleteApiCredential(input: $input)
  }
`)

export const CREATE_COMPLAINTS_FROM_PUBLIC_FORM: TypedDocumentNode<
  CreateComplaintsFromPublicFormMutation,
  CreateComplaintsFromPublicFormMutationVariables
> = graphql(`
  mutation CreateComplaintsFromPublicForm($input: CreateComplaintInput!) {
    createComplaintsFromPublicForm(input: $input) {
      success
    }
  }
`)

export const CREATE_PROJECT_TASK_FROM_PUBLIC_FORM: TypedDocumentNode<
  CreateProjectTaskFromPublicFormMutation,
  CreateProjectTaskFromPublicFormMutationVariables
> = graphql(`
  mutation CreateProjectTaskFromPublicForm($input: CreateProjectTaskInput!, $publicFormHash: String!) {
    createProjectTaskFromPublicForm(input: $input, publicFormHash: $publicFormHash) {
      success
    }
  }
`)

export const CREATE_ASSET_ISSUE_FOR_SUGGESTION: TypedDocumentNode<
  CreateAssetIssueForSuggestionMutation,
  CreateAssetIssueForSuggestionMutationVariables
> = graphql(`
  mutation CreateAssetIssueForSuggestion($suggestionId: String!) {
    createIssueFromSuggestion(suggestionId: $suggestionId) {
      id
    }
  }
`)

export const ADD_COMMENT_TO_ASSET_ISSUE: TypedDocumentNode<
  AddCommentToAssetIssueMutation,
  AddCommentToAssetIssueMutationVariables
> = graphql(`
  mutation AddCommentToAssetIssue($input: AddCommentToIssueInput!) {
    addCommentToIssue(input: $input) {
      id
      comments {
        ...CommentFields
      }
    }
  }
`)

export const CLOSE_ASSET_ISSUE: TypedDocumentNode<CloseAssetIssueMutation, CloseAssetIssueMutationVariables> = graphql(`
  mutation CloseAssetIssue($id: String!) {
    rejectIssue(id: $id) {
      id
    }
  }
`)

export const RESOLVE_ASSET_ISSUE: TypedDocumentNode<ResolveAssetIssueMutation, ResolveAssetIssueMutationVariables> =
  graphql(`
    mutation ResolveAssetIssue($id: String!) {
      resolveIssue(id: $id) {
        id
      }
    }
  `)

export const CREATE_ASSET_ISSUE: TypedDocumentNode<CreateAssetIssueMutation, CreateAssetIssueMutationVariables> =
  graphql(`
    mutation CreateAssetIssue($input: CreateAssetIssueInput!) {
      createAssetIssue(input: $input) {
        id
      }
    }
  `)

export const CONVERT_S3_FILE_TO_HTML: TypedDocumentNode<
  ConvertS3FileToHtmlMutation,
  ConvertS3FileToHtmlMutationVariables
> = graphql(`
  mutation ConvertS3FileToHtml($input: ConvertS3FileToHtmlInput!) {
    convertS3FileToHtml(input: $input)
  }
`)

export const REASSIGN_ACTIVITY_TASKS: TypedDocumentNode<
  ReassignActivityTasksMutation,
  ReassignActivityTasksMutationVariables
> = graphql(`
  mutation ReassignActivityTasks($input: ReassignActivityTasksInput!) {
    reassignActivityTasks(input: $input) {
      ...ActivityTaskFields
    }
  }
`)

export const OPT_OUT_OF_SUBSCRIPTIONS: TypedDocumentNode<
  OptOutOfSubscriptionsMutation,
  OptOutOfSubscriptionsMutationVariables
> = graphql(`
  mutation OptOutOfSubscriptions($input: OptOutOfSubscriptionsInput!) {
    optOutOfSubscriptions(input: $input)
  }
`)

export const REASSIGN_ACTIVITY_SUBSCRIPTIONS: TypedDocumentNode<
  ReassignActivitySubscriptionsMutation,
  ReassignActivitySubscriptionsMutationVariables
> = graphql(`
  mutation ReassignActivitySubscriptions($input: ReassignActivitySubscriptionsInput!) {
    reassignActivitySubscriptions(input: $input) {
      ...ActivitySubscriptionFields
    }
  }
`)

export const REASSIGN_ACTIVITY_SUBSCRIPTIONS_APPROVERS: TypedDocumentNode<
  ReassignActivitySubscriptionsApproversMutation,
  ReassignActivitySubscriptionsApproversMutationVariables
> = graphql(`
  mutation ReassignActivitySubscriptionsApprovers($input: ReassignActivitySubscriptionsInput!) {
    reassignActivitySubscriptionsApprovers(input: $input) {
      ...ActivitySubscriptionFields
    }
  }
`)

export const REASSIGN_ACTIVITY_SUBSCRIPTIONS_OWNERS: TypedDocumentNode<
  ReassignActivitySubscriptionsOwnersMutation,
  ReassignActivitySubscriptionsOwnersMutationVariables
> = graphql(`
  mutation ReassignActivitySubscriptionsOwners($input: ReassignActivitySubscriptionsInput!) {
    reassignActivitySubscriptionsOwners(input: $input) {
      ...ActivitySubscriptionFields
    }
  }
`)

export const CREATE_INTERNAL_COMPLAINT: TypedDocumentNode<
  CreateInternalComplaintMutation,
  CreateInternalComplaintMutationVariables
> = graphql(`
  mutation CreateInternalComplaint($data: ComplaintInputDTO!) {
    createInternalComplaint(data: $data) {
      id
      createdAt
      updatedAt
      metadata {
        complaintId
        complaintType
        complaintTier
        customerId
        complainantName
        source
        status
        severity
        resolution
        dateClosed
        dateFiled
        category
        rootCause
      }
    }
  }
`)

export const QUEUE_PARTNER_ONBOARDING: TypedDocumentNode<
  QueuePartnerOnboardingMutation,
  QueuePartnerOnboardingMutationVariables
> = graphql(`
  mutation QueuePartnerOnboarding($input: QueuePartnerOnboardingInput!) {
    queuePartnerOnboarding(input: $input)
  }
`)
